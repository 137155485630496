<template>
  <CentralLoader v-if="isLoading"></CentralLoader>
  <section class="pb-8" v-else>
    <v-form ref="reserveform" @submit.prevent="reserve" v-model="valid">
      <Appbar @close="$router.go(-1)" :close="true"></Appbar>
      <v-main>
        <HeroBanner
          tagline=""
          cardheight="200px"
          subtitle=""
          :image="getMerchantCover()"
          isoverlay="true"
        ></HeroBanner>
        <v-container>
          <v-row class="reserveForm">
            <v-col cols="12" class="text-center">
              <div class="text-subtitle-1 font-weight-6">
                Make a reservation with us now!
              </div>
            </v-col>
          </v-row>
          <v-row class="px-2">
            <v-col cols="6" class="px-2">
              <vc-date-picker
                v-model="reservedDate"
                mode="date"
                color="primary"
                :attributes="attrs"
                :modelConfig="modelConfig"
                :masks="masks"
                :min-date="minReservedDate"
                :disabled-dates="disabledDates"
                is-required
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    placeholder="2021-06-12"
                    class="dateandtimetxt text-caption p-0 m-0"
                    v-on="inputEvents"
                    :value="inputValue"
                    append-icon="mdi-calendar-month"
                    :disabled="storeClosed"
                    label="Date"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-select
                v-model="reservedTime"
                placeholder="12:00 pm"
                class="dateandtimetxt text-caption"
                :items="availableReservedTimes"
                item-text="label"
                item-value="value"
                label="Time"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="px-2">
            <v-col cols="3" class="px-2">
              <v-text-field
                v-model="formdata.noOfGuest"
                type="number"
                placeholder="3"
                label="Pax"
                prepend-inner-icon="mdi-account-multiple"
                :min="1"
                :rules="rules.noOfGuest"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-2">
            <v-col cols="12" class="px-2">
              <v-text-field
                label="Name"
                placeholder="eg. Sam"
                class="text-caption"
                v-model="formdata.guestName"
                :rules="rules.guestName"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2">
              <v-text-field
                label="Email"
                type="email"
                class="text-caption"
                v-model="formdata.guestEmail"
                placeholder="eg. Chen@example.com"
                :rules="rules.guestEmail"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2">
              <v-text-field
                label="Contact No"
                placeholder="eg. +9680000"
                class="text-caption"
                v-model="formdata.guestPhoneNo"
                :rules="rules.guestPhoneNo"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="px-2">
              <v-textarea
                v-model="formdata.notes"
                class="text-caption"
                outlined
                auto-grow
                rows="3"
                placeholder="Remarks: Give me extra spoon"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <div class="w-100 fixed-bottom">
        <v-bottom-navigation background-color="primary">
          <v-btn
            type="submit"
            :disabled="isLoading"
            class="white-txt-btn align-self-center"
            block
          >
            <v-icon v-if="isLoading">mdi-loading</v-icon>
            <template v-else>Confirm</template>
          </v-btn>
        </v-bottom-navigation>
      </div>
    </v-form>
  </section>
</template>

<script>
import Appbar from '@/components/partials/Appbar';
import CentralLoader from '@/components/Loaders/CentralLoader';
import HeroBanner from '@/components/Menu/HeroBanner';
import { mapActions, mapState, mapGetters } from 'vuex';
import debounce from 'debounce';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export default {
  components: {
    Appbar,
    CentralLoader,
    HeroBanner,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      valid: false,
      isLoading: false,
      modaldate: false,
      modaltime: false,
      carouselModel: 0,
      masks: {
        input: 'YYYY-MM-DD',
      },
      modelConfig: {
        timeAdjust: '00:00:00',
      },
      rules: {
        reservedDate: [(v) => !!v || 'Required'],
        reservedTime: [(v) => !!v || 'Required'],
        noOfGuest: [(v) => !!v || 'Required'],
        guestName: [(v) => !!v || 'Required'],
        guestEmail: [
          (v) => !!v || 'Required',
          (v) => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        guestPhoneNo: [(v) => !!v || 'Required'],
      },
      formdata: {
        reservedDate: null,
        reservedTime: null,
        noOfGuest: null,
        guestName: null,
        guestEmail: null,
        guestPhoneNo: null,
        notes: null,
      },
      minReservedDate: new Date(),
      reservedDate: null,
      reservedTime: null,
      attrs: [
        {
          key: 'selected',
          highlight: {
            class: 'vc-selected',
          },
          dates: new Date(),
        },
      ],
      availableReservedTimes: [],
    };
  },
  computed: {
    ...mapState('cart', ['cart']),
    ...mapState('customer', ['customer']),
    ...mapState('merchant', {
      profile: (state) => state.profile,
      businessHours: (state) => state.businessHours,
      holidays: (state) => state.holidays,
      preparationEst: (state) => state.preparationEst,
      storeClosed: (state) => state.storeClosed,
    }),
    ...mapGetters('customer', {
      guestName: 'getName',
    }),
    ...mapGetters('merchant', {
      disabledDates: 'getDisabledDates',
      isStoreClosed: 'isStoreClosed',
    }),
  },
  methods: {
    ...mapActions('reservation', ['createReservation']),
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('merchant', [
      'getMerchantByHostname',
      'getMerchantBusinessHours',
    ]),
    ...mapActions('product', [
      'getProducts',
      'getProductCategories',
      'getProductSizes',
      'getProductAddonCategories',
      'getProductAddonItems',
    ]),
    getBusinessHoursByDate(date) {
      return this.businessHours.filter(
        (x) => x.dayOfWeek == date.day() + 1
      )?.[0];
    },
    reserve() {
      if (!this.$refs.reserveform.validate()) {
        return;
      }

      this.isLoading = true;
      this.formdata.merchantId = this.profile.merchant_id;
      this.createReservation(this.formdata).then((res) => {
        if (res.status == 200) {
          this.formdata.reserveid = res.data;
          this.$router
            .push({
              name: 'reservedone',
              params: {
                reservationDetails: this.formdata,
              },
            })
            .catch(() => {});
        }
      }).catch((er) => {
        console.log(er);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    getMerchantCover() {
      return this.profile.options.merchant_photo_bg_s3
        ? `${this.cdnUrl}/${this.profile.options.merchant_photo_bg_s3}`
        : `${this.legacyCdnUrl}/upload/${this.profile.options.merchant_photo_bg}`;
    },
    async setMinReservedDate() {
      let minReservedDate = dayjs(
        this.minReservedDate.getMinutes() <= 30
          ? dayjs(this.minReservedDate).format('YYYY-MM-DD HH:30:ss')
          : dayjs(this.minReservedDate)
              .add(1, 'hour')
              .format('YYYY-MM-DD HH:00:ss'),
        'YYYY-MM-DD HH:mm:ss'
      );

      minReservedDate = minReservedDate.add(2, 'hour');

      const businessHoursToday = this.getBusinessHoursByDate(minReservedDate);

      // let orderStartAt = null;
      let orderEndAt = null;

      if (businessHoursToday) {
        // Order start and end time
        // orderStartAt = dayjs(businessHoursToday.open_am_at, 'HH:mm:ss');
        orderEndAt = dayjs(
          businessHoursToday.close_pm_at || businessHoursToday.close_am_at,
          'HH:mm:ss'
        ).subtract(1, 'hour');
      }

      if (
        this.holidays.includes(
          `${minReservedDate.startOf('d').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
        ) ||
        !businessHoursToday ||
        (minReservedDate.isAfter(orderEndAt) &&
          minReservedDate.isSame(orderEndAt, 'day'))
      ) {
        minReservedDate = minReservedDate.add(1, 'day');
      }

      this.minReservedDate = minReservedDate.toDate();

      this.reservedDate = this.minReservedDate;

      // Set for highlight selected date
      this.attrs[0].dates = this.reservedDate;
    },
    async checkUpdate() {
      this.version = JSON.parse(
        await this.getAdminOption({
          key: 'version',
        })
      );
      const localVersion = JSON.parse(localStorage.version || '{}');

      if (
        !localVersion.updatedAt ||
        dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))
      ) {
        if (JSON.parse(this.version.reset)) {
          localStorage.removeItem('cart');
        }
        localStorage.version = JSON.stringify(this.version);

        if (
          dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))
        ) {
          this.hasUpdate = true;
        }
      }
    },
  },
  async mounted() {
    // Extend dayjs plugin
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(customParseFormat);

    this.isLoading = true;
    if (this.customer) {
      this.formdata.guestName = this.guestName;
      this.formdata.guestEmail = this.customer.email;
      this.formdata.guestPhoneNo = this.customer.phoneNo;
    }

    if (!this.profile) {
      // Get merchant profile
      await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);
    }

    // Load Business Hours
    await this.getMerchantBusinessHours({
      merchantId: this.profile.merchant_id,
      service: this.service,
      stateId: this.profile.state_id,
    });

    // Set reserve start date
    await this.setMinReservedDate();

    this.isLoading = false;
  },
  watch: {
    reservedDate: function (val) {
      this.$gtag.event('select-reserved-date', {
        event_category: 'interaction',
        event_label: 'Select Reserved Date (Reservation)',
        event_value: val,
      });

      // Set for highlight selected date
      this.attrs[0].dates = this.reservedDate;

      const newReservedDate = dayjs(val).startOf('d');
      const businessHours = this.getBusinessHoursByDate(newReservedDate);

      if (
        !newReservedDate.isSame(dayjs(), 'day') &&
        (!businessHours ||
          this.holidays.includes(
            `${newReservedDate.startOf('d').format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
          ))
      ) {
        // Recursive trigger watch here
        this.minReservedDate = newReservedDate.add(1, 'day').toDate();
        this.reservedDate = this.minReservedDate;
      } else {
        // Clear previous time selection
        this.availableReservedTimes.length = 0;

        // Receiving Date is found, set Receiving Time options here
        let isStart = false;
        let isBreak = false;
        let isEnd = false;
        let nowTime = dayjs();

        const openAmAt = dayjs(businessHours.open_am_at, 'HH:mm');
        let closeAmAt = dayjs(businessHours.close_am_at, 'HH:mm');
        if (businessHours.close_am_at) {
          closeAmAt = closeAmAt.subtract(1, 'hour');
        }

        const openPmAt = dayjs(businessHours.open_pm_at, 'HH:mm');
        let closePmAt = dayjs(businessHours.close_pm_at, 'HH:mm');
        if (businessHours.close_pm_at) {
          closePmAt = closePmAt.subtract(1, 'hour');
        }

        const minTime =
          newReservedDate.isSame(dayjs(), 'day') && nowTime.isAfter(openAmAt)
            ? nowTime.add(2, 'hour')
            : openAmAt;
        for (let min = 0; min < 24 * 60 && !isEnd; min += 30) {
          // 24 * 60 mins per day
          const newReservedTime = dayjs().startOf('d').add(min, 'minute');

          // Check whether store start
          if (
            !isStart &&
            (newReservedTime.isSameOrAfter(minTime) ||
              newReservedTime.diff(minTime, 'minutes') >= -5)
          ) {
            this.reservedTime = newReservedTime.format('HH:mm');
            isStart = true;
          }

          // Check whether store break or end
          if (
            newReservedTime.isAfter(closeAmAt) ||
            newReservedTime.diff(closeAmAt, 'minutes') > 5
          ) {
            if (businessHours.open_pm_at && businessHours.close_pm_at) {
              isBreak = true;
            } else {
              isEnd = true;
            }
          }

          // Check whether store break is over
          if (
            businessHours.open_pm_at &&
            isBreak &&
            newReservedTime.isSameOrAfter(openPmAt)
          ) {
            isBreak = false;
          }

          if (
            businessHours.close_pm_at &&
            (newReservedTime.isAfter(closePmAt) ||
              newReservedTime.diff(closePmAt, 'minutes') > 5)
          ) {
            isEnd = true;
          }

          if (isStart && !isBreak && !isEnd) {
            this.availableReservedTimes.push({
              label: newReservedTime.format('hh:mm A'),
              value: newReservedTime.format('HH:mm'),
            });
          }
        }
      }

      this.formdata.reservedDate = dayjs(this.reservedDate).format('YYYY-MM-DD');
    },
    reservedTime: debounce(async function (val) {
      this.$gtag.event('select-reserved-time', {
        'event_category': 'interaction',
        'event_label': 'Select Reserved Time (Reservation)',
        'event_value': val,
      });

      this.formdata.reservedTime = val;
    }, 300),
  },
};
</script>

<style scoped>
.theme--light.v-input input::placeholder,
.theme--light.v-input textarea::placeholder {
  color: #cbcbcb;
}
.v-textarea::placeholder {
  font-size: 12px !important;
}
.reserveForm
  .theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border-color: #ffffff6b !important;
}
.white-txt-btn .v-btn__content {
  color: white;
  font-size: 15px !important;
  font-weight: bold;
}
@media screen and (max-width: 360px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    height: 100% !important;
  }
}
</style>
